import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Contacto from "../components/contacto"

import imgComoSomos from '../images/icons/como-somos.svg'
import imgNuestroObjetivo from '../images/icons/nuestro-objetivo.svg'
import imgNuestraMision from '../images/icons/nuestra-mision.svg'
import imgCardiaca from '../images/home-cardiaca.png'
import imgCardiologia from '../images/home-cardiologia.png'
import imgToracica from '../images/home-toracica.png'
import imgOtros from '../images/home-otros.png'

const IndexPage = () => (
  <Layout className="page-home">
    <SEO title="Home" />

    <section className="bloque bloque-featured">
    	<div className="container">
    		<h1>Especialistas en desarrollo y distribución de productos médicos y quirúrgicos de alta calidad</h1>
    	</div>
    </section>

	<section className="bloque bloque-nosotros">
		<div className="container">
			<h2 className="title">Sobre nosotros</h2>
			<p className="intro">Te queremos explicar un poco quienes somos y darte unas cuantas razones para que nos elijas
</p>

			<div className="row">
				<div className="col-sm-6 col-md-4">
					<article>
						<h4>Cómo somos</h4>
						<figure>
							<img className="icon" src={imgComoSomos} alt="" />
						</figure>
						<p>Dimedix posee un equipo de profesionales altamente cualificados con una amplia experiencia en el sector hospitalario.</p>
					</article>
				</div>
				<div className="col-sm-6 col-md-4">
					<article>
						<h4>Nuestro objetivo</h4>
						<figure>
							<img className="icon" src={imgNuestroObjetivo} alt="" />
						</figure>			
						<p>Aportar la tecnología más innovadora y la máxima calidad para el paciente en toda la gama de productos, con el compromiso y la colaboración de empresas del sector médico.</p>
					</article>
				</div>
				<div className="col-sm-6 col-md-4">
					<article>
						<h4>Nuestra misión</h4>
						<figure>
							<img className="icon" src={imgNuestraMision} alt="" />
						</figure>			
						<p>Ofrecer las mejores soluciones en el ámbito de la salud, que es posible gracias a la confianza de todos aquellos profesionales que han apostado por la seguridad y eficacia de nuestros productos y servicios.
						</p>
					</article>
				</div>									
			</div>
		</div>
	</section>

	<section className="bloque bloque-productos">
		<div className="container">
			<h2 className="title">Nuestros productos</h2>
			<p className="intro">Cuenta con un amplio catálogo de productos que ofrecen máxima innovación y tecnología al alcance de sus necesidades.</p>

			<div className="row">
				<div className="col-sm-6">
					<figure>
						<img className="productos-img" src={imgCardiologia} alt="Cardiología" />
					</figure>
					<div className="producto-container">
						<h4 className="titulo-cardiologia">Cardiología</h4>
						<p>Disponemos de un amplio abanico de productos cardiológicos de última generación.</p>
					</div>
				</div>
				<div className="col-sm-6">
					<figure>
						<img className="productos-img" src={imgToracica} alt="Cirugía torácica" />
					</figure>			
					<div className="producto-container">
						<h4 className="titulo-cirugia-toracica">Cirugía torácica</h4>
						<p>Ofrecemos una amplia gama de productos quirúrgicos especializados en cirugía torácica. </p>
					</div>
				</div>
				<div className="col-sm-6">
					<figure>
						<img className="productos-img" src={imgOtros} alt="Otros productos" />
					</figure>			
					<div className="producto-container">
						<h4 className="titulo-otros">Otros productos</h4>
						<p>Tenemos una serie de productos que facilitan las labores en quirófano. </p>
					</div>
				</div>
				<div className="col-sm-6">
					<figure>
						<img className="productos-img" src={imgCardiaca} alt="Cirugía cardíaca" />
					</figure>		
					<div className="producto-container">	
						<h4 className="titulo-cirugia-cardiaca">Cirugía cardíaca</h4>
						<p>Contamos con un gran catálogo de productos quirúrgicos especializados en cirugía cardíaca.</p>
					</div>
				</div>									
			</div>

			<Link to="/productos" title="Ver todos los productos" className="btn btn-primary">Ver todos los productos</Link>
		</div>
	</section>	

	<Contacto />

  </Layout>
)

export default IndexPage