import React, { useState }  from "react"
import Form from 'react-bootstrap/Form';
// import Pageclip from 'pageclip'
// const pageclipAPIKey = 'api_6by6so6dV3Cp6ykEFQliPeiDWauCkL0R'
// const pageclip = new Pageclip(pageclipAPIKey)

const Contacto = () => {

	const [serverState, setServerState] = useState({
	  submitting: false,
	  status: null
	});

	const handleServerResponse = (ok, msg, form) => {
	  setServerState({
	    submitting: false,
	    status: { ok, msg }
	  });
	  if (ok) {
	    form.reset();
	  }
	};

	const handleOnSubmit = e => {
		e.preventDefault();
		const form = e.target;
		setServerState({ 
			submitting: true,
		});
		
		var proxyUrl = 'https://cors-anywhere.herokuapp.com/',
		    targetUrl = 'https://www.form-data.com/_functions/submit/9hxc115ed4jza4dnpzq9d'

		fetch(proxyUrl + targetUrl, {
			method: 'POST',
			body: new FormData(form)
		}).then(r => {
          handleServerResponse(true, "Gracias por contactar con dimedix. En breve nos pondremos en contacto.", form);
        })
        .catch(r => {
          handleServerResponse(false, r.response.data.error, form);
        });
    };


	// const handleOnSubmit = e => {
	// 	e.preventDefault();
	// 	const form = e.target;
	// 	setServerState({ 
	// 		submitting: true,
	// 	});


	// 	fetch("https://getform.io/f/e853d58d-ec2c-4169-81b6-045452b8c614", {
	// 		method: 'POST',
	// 		body: new FormData(form)
	// 	}).then(r => {
 //          handleServerResponse(true, "Gracias por contactar con dimedix. En breve nos pondremos en contacto.", form);
 //        })
 //        .catch(r => {
 //          handleServerResponse(false, r.response.data.error, form);
 //        });
 //    };



	return (
	    <section id="contacto" className="bloque bloque-contacto">
			<div className="container">
			 	<h2 className="title">Contacto</h2>
			 	<p className="intro">Si tienes alguna duda, quieres tener más información sobre algun producto o estás buscando algún producto en concreto, escribenos y nos pondremos en contacto contigo. </p>

				<form onSubmit={handleOnSubmit} className="box">
					<div className="row">
						<div className="col-sm-6">
							<Form.Group controlId="nombre">
								<Form.Label className="label-required">Nombre</Form.Label>
								<Form.Control 
									name="nombre" 
									required
									placeholder="Escribe tu nombre (y apellidos)"
									/>
							</Form.Group>
						</div>
						<div className="col-sm-6">

						</div>		
						<div className="col-sm-6">
							<Form.Group controlId="email">
								<Form.Label className="label-required">Tu correo electrónico</Form.Label>
								<Form.Control 
									type="email"
									name="email" 
									required
									placeholder="Escribe tu e-mail"
									/>
							</Form.Group>
						</div>
						<div className="col-sm-6">
							<Form.Group controlId="telefono">
								<Form.Label>Teléfono</Form.Label>
								<Form.Control 
									type="text"
									name="telefono" 
									placeholder="Escribe tu teléfono"
									/>
							</Form.Group>
						</div>	
						<div className="col-sm-6">
							<Form.Group controlId="hospital">
								<Form.Label>Hospital</Form.Label>
								<Form.Control 
									type="text"
									name="hospital" 
									placeholder="Escribe el hospital donde trabajas"
									/>
							</Form.Group>
						</div>
						<div className="col-sm-6">
							<Form.Group controlId="especialidad">
								<Form.Label>Especialidad</Form.Label>
								<Form.Control 
									type="text"
									name="especialidad" 
									placeholder="Escribe tu especialidad"
									/>
							</Form.Group>
						</div>											
					</div>	
					<Form.Group controlId="asunto">
						<Form.Label className="label-required">Asunto</Form.Label>
						<Form.Control 
							type="text"
							name="asunto" 
							required
							placeholder="Escribe el asunto del mensaje"
							/>
					</Form.Group>
					<Form.Group controlId="descripcion">
						<Form.Label className="label-required">Descripción</Form.Label>
						<Form.Control 
							type="text"
							name="descripcion" 
							as="textarea"
							required
							placeholder="Escribe el cuerpo de tu mensaje"
							/>			
					</Form.Group>

					<button 
						type="submit" 
						className="btn btn-primary"
						disabled={serverState.submitting}
						>
						Enviar
					</button>

		            {serverState.status && (
		                <p className={!serverState.status.ok ? "errorMsg" : "okMsg"}>
		                	{serverState.status.msg}
		                </p>
		            )}				
				</form>
			</div>
		</section>
	);
};

export default Contacto